import { createAPI } from "../utils/request"

export const monitorList = data => createAPI(`base/hosp/monitor/list`, 'get', data) //查询院内系统运行状态

export const addMonitor = data => createAPI(`base/hosp/monitor/create`, 'post', data) //添加节点

export const editMonitor = data => createAPI(`base/hosp/monitor/save`, 'post', data) //修改节点

export const delMonitor = data => createAPI(`base/hosp/monitor/delete/${data.id}`, 'get', data) //删除节点


