<template>
    <div>
        <q-dialog v-model="editConfigs" persistent>
            <q-card style="min-width: 350px">
                <q-card-section>
                    <div class="text-h6">修改节点</div>
                </q-card-section>

                <q-card-section class="q-pt-none">
                    <!-- 省份选择 -->
                    <selectp @provincechange='provincechange' class='add' :options='editDatas.provincelist' :model.sync='editDatas.provinceId' :label='"请选择省份*"' />
                    <!-- 城市选择 -->
                    <selectc @citychange='citychange' class='add' :options='editDatas.citylist' :model.sync='editDatas.cityId' :label='"请选择城市*"'/>
                    <!-- 医院选择 -->
                    <selecth @hospchange='hospchange' class='add' :options='editDatas.hosplist' :model.sync='editDatas.hospId' :label='"请选择医院*"'/>
                </q-card-section>

                <q-card-actions align="right" class="text-primary">
                    <q-btn flat label="取消" @click='editClose' />
                    <q-btn flat label="保存" @click='editOk' />
                </q-card-actions>
            </q-card>
        </q-dialog>
    </div>
</template>
<script>
import selectp from '@/components/Order/select/provinceselect.vue' // 省份下拉框组件
import selectc from '@/components/Order/select/cityselect.vue' // 城市下拉框组件
import selecth from '@/components/Order/select/hospselect.vue' // 医院下拉框组件
import { getprovince,getcity,gethosplist,getoptions } from '@/api/components/checkcitys.js'; // 获取城市接口
import { editMonitor } from '@/api/monitor.js'
import { notifys } from '@/api/components/Notify.js'

export default {
    props:{
        editConfig:{
            type: Boolean,
            required: true,
        },
        editData:{
            type: Object,
            required: true,
        }
    },
    data() {
        return {

        }
    },
    created(){
    },
    computed:{
        editConfigs:{
            get(){
                return this.editConfig
            },
            set(v){
                this.$emit('update:editConfig',v)
            }
        },
        editDatas:{
            get(){
                return this.editData
            },
            set(v){
                this.$emit('update:editData',v)
            }
        }
    },
    components:{
        selectp,
        selectc,
        selecth,
    },
    methods:{
        // 省份改变时
        async provincechange(){
            this.editDatas.citylist=[] //清空城市
            this.editDatas.cityId =''
            this.editDatas.hosplist=[] //清空医院
            this.editDatas.hospId =''

            this.editDatas.citylist = await getcity(this.editDatas.provinceId.id) // 获取所有省份
        },
        // 城市改变时
        async citychange(){
            this.editDatas.hosplist=[] //清空医院
            this.editDatas.hospId =''

            this.editDatas.hosplist = await gethosplist(this.editDatas.cityId.id) // 获取所有省份
        },
        // 医院改变时
        hospchange(){},
        // 确定保存编辑
        editOk(){
            this.$q.dialog({
                title: '保存',
                message: `你确定要保存对当前节点的修改吗?`,
                ok: { label: '确定',color: 'red'},
                cancel: { label: '取消',flat: true},
            }).onOk(async() => {
                try{
                    const {data:res} = await editMonitor({
                        hospId: this.editDatas.hospId.id,
                        cityId: this.editDatas.cityId.id,
                        provinceId: this.editDatas.provinceId.id,
                        id: this.editDatas.id
                    })
                    console.log(res);
                    if(res.code===20000){

                        this.editClose()
                        this.$emit('updateList')
                        notifys('positive',res.message)
                    }else{
                        notifys('negative',res.message)
                    }
                }catch (e){
                    console.log(e);
                }
            })
        },
        // 关闭时
        editClose(){
            this.$emit('editClose')
        },
    },
}
</script>
<style lang="less" scoped>

</style>
