<template>
    <div>
        <q-dialog v-model="addConfigs" persistent  @hide="addClose">
            
            <q-card style="min-width: 350px">
                <q-card-section>
                    <div class="text-h6">新增节点</div>
                </q-card-section>
                    <q-card-section class="q-pt-none">
                        <q-form ref="addNode" greedy>
                            <!-- 省份选择 -->
                            <selectp @provincechange='provincechange' class='add' :options='addDatas.provincelist' :model.sync='addDatas.provinceId' :label='"请选择省份*"' />
                            <!-- 城市选择 -->
                            <selectc @citychange='citychange' class='add' :options='addDatas.citylist' :model.sync='addDatas.cityId' :label='"请选择城市*"'/>
                            <!-- 医院选择 -->
                            <selecth @hospchange='hospchange' class='add' :options='addDatas.hosplist' :model.sync='addDatas.hospId' :label='"请选择医院*"'/>
                        </q-form>
                    </q-card-section>
                <q-card-actions align="right" class="text-primary">
                    <q-btn flat label="取消" @click="addClose" />
                    <q-btn flat label="添加" @click="addOk" />
                </q-card-actions>
            </q-card>
            
        </q-dialog>
    </div>
</template>
<script>
import selectp from '@/components/Order/select/provinceselect.vue' // 省份下拉框组件
import selectc from '@/components/Order/select/cityselect.vue' // 城市下拉框组件
import selecth from '@/components/Order/select/hospselect.vue' // 医院下拉框组件
import { getprovince,getcity,gethosplist,getoptions } from '@/api/components/checkcitys.js'; // 获取城市接口
import { addMonitor } from '@/api/monitor.js'
import { makeDebounce } from "@/utils/debounce.js";  
import { notifys } from '@/api/components/Notify.js'

export default {
    props:{
        addConfig:{
            type: Boolean,
            required: true,
        },
        addData:{
            type: Object,
            required: true,
        }
    },
    data() {
        return {

        }
    },
    created(){
    },
    computed:{
        addConfigs:{
            get(){
                return this.addConfig
            },
            set(v){
                this.$emit('update:addConfig',v)
            }
        },
        addDatas:{
            get(){
                return this.addData
            },
            set(v){
                this.$emit('update:addData',v)
            }
        }
    },
    components:{
        selectp,
        selectc,
        selecth,
    },
    methods:{
        // 省份改变时
        async provincechange(){
            this.addDatas.citylist=[] //清空城市
            this.addDatas.cityId =''
            this.addDatas.hosplist=[] //清空医院
            this.addDatas.hospId =''

            this.addDatas.citylist = await getcity(this.addDatas.provinceId.id) // 获取所有省份
        },
        // 城市改变时
        async citychange(){
            this.addDatas.hosplist=[] //清空医院
            this.addDatas.hospId =''

            this.addDatas.hosplist = await gethosplist(this.addDatas.cityId.id) // 获取所有省份
        },
        // 医院改变时
        hospchange(){},
        // 确定添加时
        addOk:makeDebounce(async function(){
            const { data:res } = await addMonitor({
                hospId: this.addDatas.hospId.id,
                cityId: this.addDatas.cityId.id,
                provinceId: this.addDatas.provinceId.id
            })
            if(res.code===20000){
                //成功提示
                notifys('positive',res.message)
                this.addClose()
                this.$emit('updateList')
            }else{
                //失败提示
                notifys('negative',res.message)
            }
        },500),
        // 新增关闭时
        addClose(){
            this.$emit('addClose')
        },
    },
}
</script>
<style lang="less" scoped>

</style>
