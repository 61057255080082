<template>
    <div id="systemMonitoring" class="q-pa-md q-gutter-sm">
        <!-- 面包屑 -->
        <q-breadcrumbs>
            <q-breadcrumbs-el icon="iconfont icon-zhuye" label="主页" to="/orm/dashboard" />
            <q-breadcrumbs-el label="系统管理" icon="iconfont icon-xitongfuwu" />
            <q-breadcrumbs-el label="院内系统监控" icon="iconfont icon-xitongjiankong" to="/system/systemMonitoring" />
        </q-breadcrumbs>
        <!-- 内容区 -->
        <div class='systemMonitoringbox' id='systemMonitoringbox'>
            <!-- 搜索区 -->
            <q-card class="my-card" style="margin-bottom: 10px;">
                <q-card-section>
                    <div class="row" id="searchbox">
                        <div class="col-9 search">
                            <q-input
                                :disable='Permissionslist.filter(item=>item==="hosp.monitor.list").length>0?false:true'
                                class='operation' :dense='true' outlined  v-model="queryData.hospName" label="医院名称" @blur='getMonitorList'
                            >
                                <template v-if="queryData.hospName" v-slot:append>
                                    <q-icon name="close" @click.stop="queryData.hospName = ''" class="cursor-pointer" style="font-size: 16px;" @click='getMonitorList'/>
                                </template>
                            </q-input>
                            <!-- <el-button icon="el-icon-refresh" size="mini" circle @click='refresh'></el-button> -->
                        </div>

                        <div class="col  addbtn">
                            <el-button
                                type="primary"
                                size='medium'
                                @click='add'
                                :disabled='Permissionslist.filter(item=>item==="hosp.monitor.add").length>0?false:true'
                            >
                                添加节点
                            </el-button>
                        </div>
                    </div>
                </q-card-section>
            </q-card>
            <!-- 列表区域 -->
            <!-- 
                v-loading="ordervisible"
                element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(55,55,55,0.2)"
                :max-height="tableHeight"
                @sort-change="sortthiscolumn"
             -->
            <q-card class="my-card">
                <q-card-section>
                    <el-table
                        ref="multipleTable"
                        :data="monitorlist"
                        tooltip-effect="dark"
                        style="width: 100%"
                        v-loading="ordervisible"
                        element-loading-text="拼命加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(55,55,55,0.2)"
                        :max-height="tableHeight"
                    >
                        <template slot="empty">
                            {{nodatetype}}
                        </template>
                        <!-- 节点名称 -->
                        <el-table-column show-overflow-tooltip  align='center' prop="hospName" label="节点名称" min-width="110"></el-table-column>
                        <!-- 运行状态 -->
                        <el-table-column show-overflow-tooltip  align='center' prop="status" label="运行状态" min-width="110">
                            <template slot-scope="{ row }">
                                <q-badge v-if='row.status && (row.toUpload != 0 || row.uploadPending != 0)' rounded color='light-blue-6' />
                                <q-badge v-else-if='row.status && (row.toUpload == 0 && row.uploadPending == 0)' rounded color='light-green-7' />
                                <q-badge v-else rounded color="grey-6" />
                            </template>
                        </el-table-column>
                        <!-- 磁盘使用率 -->
                        <el-table-column class="diskContent" show-overflow-tooltip align='center' label="磁盘使用率" min-width="120">
                            <template slot-scope="{ row }">
                                <span>
                                    <el-popover placement="bottom" trigger="hover" popper-class='popoverBackB'>
                                        <div class='popoverColor'>磁盘总大小: {{ row.diskTotal }}</div>
                                        <div class='popoverColor'>已使用: {{ row.diskUsed }}</div>
                                        <div class='popoverColor'>剩余: {{ row.diskAvail }}</div>
                                        <!-- 显示磁盘使用率 -->
                                        <span slot="reference">
                                            {{ row.diskUsedPercent }}%
                                        </span>
                                    </el-popover>
                                </span>
                            </template>
                        </el-table-column>
                        <!-- 授权有效期 -->
                        <el-table-column show-overflow-tooltip  align='center' label="授权有效期" min-width="120">
                            <template slot-scope="{ row }">
                                {{ 
                                    row.expireDate
                                }}
                            </template>
                        </el-table-column>
                        <!-- 数据传输情况 -->
                        <el-table-column class="diskContent" show-overflow-tooltip align='center' label="数据传输成功率" min-width="145">
                            <template slot-scope="{ row }">
                                <span>
                                    <el-popover placement="bottom" trigger="hover" popper-class='popoverBackB'>
                                        <div class='popoverColor'>总数据量: {{ row.uploadTotal }}</div>
                                        <div class='popoverColor'>待传输: {{ row.toUpload }}</div>
                                        <div class='popoverColor'>正在传输: {{ row.uploadPending }}</div>
                                        <div class='popoverColor'>传输失败: {{ row.uploadFail }}</div>
                                        <div class='popoverColor'>传输成功: {{ row.uploadSuccess }}</div>
                                        <!-- 显示磁盘使用率 -->
                                        <span slot="reference">
                                            {{ row.dataSyncPercent }}%
                                        </span>
                                    </el-popover>
                                </span>
                            </template>
                        </el-table-column>
                        <!-- HOST -->
                        <el-table-column show-overflow-tooltip  align='center' prop="host" label="主机地址" min-width="110"></el-table-column>
                        <!-- 创建日期 -->
                        <el-table-column show-overflow-tooltip  align='center' prop="createTime" label="创建日期" min-width="151"></el-table-column>
                        <!-- 操作 -->
                        <el-table-column align='center' min-width="230" width="250" fixed="right" label="操作">
                            <template slot="header">
                                <div class='newCaozuobox'>
                                    <div class='czTit'>操作</div>
                                    <div class='czpopoverbtn'>
                                        <el-button icon="el-icon-refresh" size="mini" circle @click='refresh'
                                            :disabled='Permissionslist.filter(item=>item==="hosp.monitor.list").length>0?false:true'
                                        >
                                        </el-button>
                                        <!-- <el-popover
                                            placement="bottom"
                                            width="160"
                                            trigger="click"
                                        >
                                            <q-checkbox v-model="orderID" label="订单号" color="cyan" />
                                            
                                            <el-button icon="el-icon-s-grid" size='medium' slot="reference" style="padding: 5px 10px;border: none;">
                                                <i class="el-icon-caret-bottom"></i>
                                            </el-button>
                                        </el-popover> -->
                                    </div>
                                </div>
                            </template>
                            <template slot-scope="scope">
                                <!-- 领取任务  :disable='scope.row.checkStatus==0? false : true'-->
                                <q-btn
                                    class="czbtn" 
                                    size="sm" 
                                    color="blue" 
                                    icon="iconfont icon-Edit"
                                    @click="edit(scope.row)"
                                    :disable='Permissionslist.filter(item=>item==="hosp.monitor.edit").length>0?false:true'
                                    >
                                    <q-tooltip content-class="bg-blue" :offset="[10, 10]">
                                        编辑节点
                                    </q-tooltip>  
                                </q-btn> 
                                <!-- 提交审核 :disable='scope.row.checkStatus==0? true : false'-->
                                <q-btn 
                                    class="czbtn"  
                                    size="sm" 
                                    color="red-5" 
                                    icon="iconfont icon-ashbin"
                                    @click="del(scope.row)"
                                    :disable='Permissionslist.filter(item=>item==="hosp.monitor.delete").length>0?false:true'
                                    >
                                    <q-tooltip content-class="bg-red-5" :offset="[10, 10]">
                                        删除
                                    </q-tooltip>  
                                </q-btn>      
                            </template>
                        </el-table-column>
                    
                    </el-table>
                    <!-- 分页组件 -->
                    <elementPagination class="Paginationstyle" :total='queryData.total' :queryData='queryData' @getList='getreconList()' @changepageSize='changepageSize' @changePage='changePage'/>
                </q-card-section>
            </q-card>
        </div>

        <!-- 新增节点 -->
        <addNode
            ref='addNode'
            :addConfig.sync='addConfig'
            :addData.sync='addData'
            @addClose='addClose'
            @updateList='getMonitorList'
        />
        
        <!-- 编辑节点 -->
        <editNode
            :editConfig.sync='editConfig'
            :editData.sync='editData'
            @editClose='editClose'
            @updateList='getMonitorList'
        />
    </div>
</template>
<script>
import { getHeight } from '@/api/getHeight.js'; // 获取列表最高高度

import elementPagination from '@/components/ElementUI/elementPagination.vue'; // 分页组件
import addNode from '@/components/SystemMonitoring/addNode.vue'; // 新增节点
import editNode from '@/components/SystemMonitoring/editNode.vue'; // 新增节点

import { monitorList,addMonitor,editMonitor,delMonitor } from '@/api/monitor.js'
import { getprovince,getcity,gethosplist,getoptions } from '@/api/components/checkcitys.js'; // 获取城市接口
import { makeDebounce } from "@/utils/debounce.js";  
import { mapState } from 'vuex'
import { notifys } from '@/api/components/Notify.js'


export default {
    data() {
        return {
            nodatetype:'',
            ordervisible: false,//控制列表加载画面
            tableHeight:500, //列表最大高度
            // 分页设置
            queryData:{ // element分页信息
                page: 1,
                pageSize: 10,
                total:0,
                hospName:'', //医院名称
                // patientName:'', // 患者名称
                // orderId: '', // 订单号
            },
            monitorlist:[], //所有节点

            addConfig: false, // 控制新增
            addData:{
                provincelist:[],
                citylist:[],
                hosplist:[],

                provinceId:'',
                cityId:'',
                hospId:'',
            }, //新增数据
            
            editConfig: false, // 控制编辑
            editData:{
                provincelist:[],
                citylist:[],
                hosplist:[],

                provinceId:'',
                cityId:'',
                hospId:'',
                id:'', //节点id
            },//编辑数据
        }
    },
    created(){
        this.getMonitorList()
    },
    mounted() {
        
    },
    computed:{
        ...mapState([
            'Permissionslist' // 权限词典
        ])
    },
    components:{
        elementPagination,
        addNode,
        editNode,
    },
    methods:{
        refresh:makeDebounce(function(evt){
            let target = evt.target;
            if(target.nodeName == "SPAN"){
                target = evt.target.parentNode;
            }
            target.blur()
            this.getMonitorList()
        },300),
        //获取列表
        getMonitorList(){
            this.nodatetype=''
            this.ordervisible = true
            setTimeout(async() =>{
                const { data:res } = await monitorList({
                    ...this.queryData,
                })
                console.log('节点列表',res);
                if(res.code===20000){
                    this.monitorlist = res.data.nodes
                    this.queryData.total=res.data.totals*1

                    this.monitorlist.map(e=>{
                        var expireDate = this.expireDateZH(e.expireDate)
                        // console.log(expireDate);
                        e.expireDate = expireDate
                    })

                    this.ordervisible = false
                    this.nodatetype='暂无数据'
                }else{
                    this.ordervisible = false
                    this.nodatetype='暂无数据'
                }
                let height = document.getElementById('searchbox').offsetHeight + 100
                this.tableHeight = getHeight('systemMonitoringbox',height)
            },1000)
            // let height = document.getElementById('searchbox').offsetHeight + 20
            // this.tableHeight = getHeight('systemMonitoringbox',height)
        },
        //新增节点
        async add(){
            this.addData.provincelist = await getprovince() // 获取所有省份
            this.addConfig = true
        },
        addClose(){
            this.addData.provincelist = []
            this.addData.citylist = []
            this.addData.hosplist = []
            this.addData.provinceId = ''
            this.addData.cityId = ''
            this.addData.hospId = ''
            this.addConfig = false
        },
        // 编辑节点
        async edit(row){
            console.log(row);
            this.editData.id=row.id

            this.editData.provincelist=await getprovince() // 获取所有省份
            this.editData.citylist= await getcity(row.provinceId) // 获取所有城市
            this.editData.hosplist=await gethosplist(row.cityId) //获取所有医院

            // 获取省份所选项
            this.editData.provinceId = getoptions(this.editData.provincelist,row.provinceId);
            // 获取城市所选项
            this.editData.cityId = getoptions(this.editData.citylist,row.cityId)
            //获取所选医院
            this.editData.hospId = getoptions(this.editData.hosplist,row.hospId) 

            this.editConfig = true
        },
        editClose(){
            this.editData.provincelist = []
            this.editData.citylist = []
            this.editData.hosplist = []
            this.editData.provinceId = ''
            this.editData.cityId = ''
            this.editData.hospId = ''
            this.editConfig = false
        },
        // 删除节点
        del(row){
            this.$q.dialog({
                title: '删除',
                message: `你确定要删除此节点吗?`,
                ok: { label: '确定',color: 'red'},
                cancel: { label: '取消',flat: true},
            }).onOk(async() => {
                try{
                    const {data:res} = await delMonitor({id:row.id})
                    console.log(res);
                    if(res.code===20000){
                        // 判断当前删除的是否为最后一页的最后一个记录
                        // 遍历词库列表
                        this.monitorlist.forEach((item, index) => {
                            // 如果当前id是要删除的id
                            if (item.id === row.id) {
                                // 判断规则:（当前页码-1）*页容量+要被删除项的索引+1===总条数 && 判断被删项索引是否为0 && 当前不是第一页
                                if (
                                    (this.queryData.page - 1) * this.queryData.pageSize + index + 1 ===
                                        this.total &&
                                    index === 0 &&
                                    this.queryData.page > 1
                                ) {
                                    // 如果是就将页码回到上一页
                                    this.queryData.page--;
                                }
                            }
                        });
                        this.getMonitorList()
                        notifys('positive',res.message)
                    }else{
                        notifys('negative',res.message)
                    }
                }catch (e){
                    console.log(e);
                }
            })
        },
        
        //出生日期转换
        expireDateZH(expireDate){
            if(expireDate){
                let arr = expireDate.split('');
                arr.splice(4,0,'-');
                arr.splice(7,0,'-');
                var Date = arr.join('')
                return Date
            }else{
                return ''
            }
        },
        // 分页发送变化函数
        changepageSize(val){
            this.queryData.pageSize = val
            this.getMonitorList()
        },
        changePage(val){
            this.queryData.page = val
            this.getMonitorList()
        },
    },
}
</script>
<style lang="less" scoped>
#systemMonitoring{
  height: 100%;
}
.systemMonitoringbox{
  height: 90%;
}
.search{
  display: flex;
  flex-direction: row ;
//   align-items: flex-end;
  align-items: center;
  flex-wrap: wrap;
  .operation{
    // margin: .625rem .625rem 0 .625rem;
    margin: 0 .625rem 0 .625rem;
    width: 12.5rem;
  }
}
.addbtn{
  display: flex;
  flex-direction: row ;
  justify-content: flex-end;
  align-items: center;
//   align-items: flex-end;
  flex-wrap: wrap;
    .el-button--primary {
        color: #FFF;
        background-color: #409EFF  ;
        border-color: #409EFF  ;
    }
}
.czbtn{
  margin-right: .75rem;
}
.newCaozuobox{
  // display: flex;
  // justify-content: space-between;
  .czTit{
    float: left;
    text-align: right;
    width:50%;
  }
  .czpopoverbtn{
    float: left;text-align: right;width:50%;
  }
}

//popover 中字体颜色
.popoverColor{
    color: white;
    font-weight: bold;
}
//运行状态样式
.q-badge{
    padding: 10px;
}
//分页器样式
.Paginationstyle{
  margin-top: .9375rem;
}
</style>
